@import './colors';

.color {
  &--black {
    color: $black !important;
  }

  &--warm-pink {
    color: $warm-pink !important;
  }

  &--sapphire {
    color: $sapphire !important;
  }

  &--golden-yellow {
    color: $golden-yellow !important;
  }

  &--yellow-grey {
    color: $yellow-grey !important;
  }

  &--white {
    color: $white !important;
  }

  &--pale-grey {
    color: $pale-grey !important;
  }

  &--pale-lilac {
    color: $pale-lilac !important;
  }

  &--tealish {
    color: $tealish !important;
  }

  &--blue-grey {
    color: $blue-grey !important;
  }

  &--pale-lilac-two {
    color: $pale-lilac-two !important;
  }

  &--grey {
    color: $grey !important;
  }

  &--disabled-grey {
    color: $disabled-grey !important;
  }
}
