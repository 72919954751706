@import './colors';
@import './fonts';
@import './global-variables';
@import './typography';
@import './mixins';
@import './font-sizes';

html,
body {
  @include width-until(334px) {
    font-size: 3px;
  }

  @include width-between(334px, 384px) {
    font-size: 4px;
  }

  @include width-between(384px, 512px) {
    font-size: 4px;
  }

  @include width-between(512px, 640px) {
    font-size: 7px;
  }

  @include width-between(640px, 768px) {
    font-size: 7px;
  }

  @include width-between(768px, 896px) {
    font-size: 9px;
  }

  @include width-between(896px, 1024px) {
    font-size: 10px;
  }
/*
  @include width-between(1024px, 1210px) {
    font-size: 12px;
  }

  @include width-between(1210px, 1460px) {
    font-size: 12px;
  }

  @include width-between(1460px, 1710px) {
    font-size: 14px;
  }
*/
  @include width-between(1710px, 1920px) {
    font-size: 15px;
  }

  @include width-between(1920px, 2150px) {
    font-size: 17px;
  }


  @include width-between(2150px, 2228px) {
    font-size: 19px;
  }

  @include width-from(2228px) {
    font-size: 20px;
  }
}

html,
body,
#root {
  height: 100%;
}

#root>div {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}

.position-relative {
  position: relative;
}

a,
.text--link {
  font-family: $primaryFontFamily;
  font-size: .875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: $blue-grey;
  transition: 0.25s ease;
  cursor: pointer;

  &:hover {
    color: $sapphire;
    text-decoration: none;
  }
}

.width {
  &--auto {
    width: auto;
  }

  &--50 {
    width: 50% !important;
  }
}

.overflow {
  &--hidden {
    overflow: hidden;
  }
}

.text {
  &--disabled {
    color: $blue-grey;
  }

  &--small {
    font-family: $primaryFontFamily;
    font-size: .75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $sapphire;
  }
}

.h1 {
  &--default {
    font-size: 1.375rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #000033;
  }
}

.cursor--pointer {
  cursor: pointer;
}

.badge { 
  border-radius: .625rem;
  padding: .3rem .6rem;
  width: fit-content;
  font-size: .9375rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  
  color: #4500b5;
  background-color: #ebdeff;

  &--no {
    background-color: hsla(0, 91%, 79%, .3);
    color: black;

  }

  &--yes {
    background-color: hsla(113, 91%, 79%, .3);
    color: black;
  }
}

.badge-green {
  background-color: #CEFDDC;
  color: #296F49;
}

.badge-red {
  background-color: #F5B9BC;
  color: #841316;
}


.no-border-radius {
  border-radius: 0 !important;
}

.width--auto {
  width: auto !important;
}

.width--100{
  width: 100% !important;
}

select { 
  outline: none;
}