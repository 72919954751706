html,
body {
  @include width-between(0px, 240px) {
    font-size: 1px;
  }

  @include width-between(240px, 360px) {
    font-size: 2px;
  }

  @include width-between(360px, 480px) {
    font-size: 3px;
  }

  @include width-between(480px, 600px) {
    font-size: 4px;
  }

  @include width-between(600px, 720px) {
    font-size: 5px;
  }

  @include width-between(720px, 840px) {
    font-size: 6px;
  }

  @include width-between(840px, 960px) {
    font-size: 7px;
  }

  @include width-between(960px, 1080px) {
    font-size: 8px;
  }

  @include width-between(1080px, 1200px) {
    font-size: 9px;
  }

  @include width-between(1200px, 1320px) {
    font-size: 10px;
  }

  @include width-between(1320px, 1440px) {
    font-size: 11px;
  }

  @include width-between(1440px, 1560px) {
    font-size: 12px;
  }

  @include width-between(1560px, 1680px) {
    font-size: 13px;
  }

  @include width-between(1680px, 1800px) {
    font-size: 14px;
  }

  @include width-between(1800px, 1920px) {
    font-size: 15px;
  }

  @include width-between(1920px, 2040px) {
    font-size: 16px;
  }
}