// Responsiveness
=portrait
  @media screen and (max-aspect-ratio: 13/10)
    @content
    
=landscape
   @media screen and (min-aspect-ratio: 13/10)
    @content

=width-from($from)
  @media screen and (min-width: $from)
    @content
 
=width-between($from, $until)
  @media screen and (min-width: $from) and (max-width: $until - 1px)
    @content

=width-until($until)
  @media screen and (max-width: $until - 1px)
    @content

=width-at($value)
  @media screen and (min-width: $value) and (max-width: $value)
    @content