$black: #2c2c2c;
$warm-pink: #f64f7b;
$sapphire: #152ba8;
$golden-yellow: #ffcb1f;
$yellow-grey: #6a5200;
$white: #ffffff;
$pale-grey: #f2f5f9;
$pale-lilac: #e1e3ef;
$tealish: #28d7b5;
$blue-grey: #9397ac;
$pale-lilac-two: #eff1ff;
$grey: #9497ab;
$disabled-grey: #bfc1cc;
